import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import Carousel from "nuka-carousel"

const HobsonsPage = ({ data }) => (
  <Layout>
    <SEO title="Hobsosn Bay Dental Project" />
    <h1>Hobsons Bay Dental Project</h1>

    <p>
      This commercial dental fit-out project was built for Dr Dan Andrews of
      Hobson's Bay Dental Clinic, who expanded his Altona North business to
      Williamstown, where we delivered a high end finish clinic, on a cutting
      edge design.
    </p>
    <div className="flex justify-center">
      <div className="w-2/3 h-auto py-6">
        <Carousel renderBottomCenterControls={false}>
          {data.hobsonsPhotos.edges.map(edge => {
            return <Img fluid={edge.node.childImageSharp.fluid} />
          })}
        </Carousel>
      </div>
    </div>
  </Layout>
)

export default HobsonsPage

export const query = graphql`
  query {
    hobsonsPhotos: allFile(
      filter: { 
        absolutePath: { regex: "/projects/commercial/hobsons-bay-dental/" }
        extension: { regex: "/(jpg)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
